<template>
    <div class="m_products_page">
        <m-nav-bar :showMenu="false"></m-nav-bar>
        <div class="banner flex_align_end flex_justify_center"
            style="background-image: url('https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/products_banner.png');">
            <img class="gif"
                src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/products_banner.gif"
                alt="">
            <img class="tag absolute_l_center" @click="scrollById('container')" src="@/assets/img_mobile/tag_07.png"
                alt="">
        </div>
        <div class="container" id="container" ref="container">
            <div class="item" :ref="`product${index}`" :id="`product-${item.id}`" v-for="(item, index) of curData"
                :key="index">
                <div class="title_wrap">
                    <div :ref="`titleWrap${index}`"
                        class="wrapper HurmeGeometricSans3_Bold flex_align_center flex_justify_between">
                        <div class="flex_align_end">
                            <h1>LEDO</h1>
                            <h2>{{item.abbreviation}}</h2>
                        </div>
                        <div class="open_btn click_cursor" @click="handleCollapse(item.isHide, item.id)">
                            <img class="absolute_all_0 w_h_100" :class="item.isHide ? 'actived' : ''"
                                src="@/assets/img_mobile/collapse.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="wrapper flex_col flex_align_center">
                        <div class="img_wrap flex_align_end flex_justify_between">
                            <img class="img_1" :src="item.mProductImgs[0]" alt="">
                            <img class="img_2" :src="item.mProductImgs[1]" alt="">
                        </div>
                        <div class="inspiration_wrap">
                            <h3 class="HurmeGeometricSans3_SemiBold">Design concept</h3>
                            <p class="inspiration">{{item.inspiration}}</p>
                        </div>
                        <div class="img_3_wrap flex_align_center">
                            <img class="img_3" :src="item.mProductImgs[2]" alt="">
                            <span class="second_title">{{item.secondTitle}}</span>
                        </div>
                        <img class="img_4" :src="item.mProductImgs[3]" alt="">
                    </div>
                </div>
            </div>
        </div>
        <m-scroll-bar></m-scroll-bar>
        <m-foot></m-foot>
        <m-to-top></m-to-top>
    </div>
</template>

<script>
    import mNavBar from "@/components/mobile/m-nav-bar.vue";
    import mScrollBar from "@/components/mobile/m-scroll-bar.vue"
    import mFoot from "@/components/mobile/m-foot.vue"
    import mToTop from "@/components/mobile/m-to-top.vue"

    import { mapState } from 'vuex'
    export default {
        name: 'm-products',
        data() {
            return {
                curData: [],
                clientHeight: 0, // 浏览器可见区域高度
                titleHeight: 0 // 每个item的title_wrap的高度
            }
        },
        mounted() {
            let id = this.$route.params.id
            if (id) {
                this.scrollById(id)
            }

            this.clientHeight = window.innerHeight || document.documentElement.clientHeight

            document.addEventListener('scroll', this.handleScroll1)
            document.addEventListener('scroll', this.handleScroll2)
            window.addEventListener('resize', this.onResize)
        },
        destroyed() {
            document.removeEventListener('scroll', this.handleScroll1)
            document.removeEventListener('scroll', this.handleScroll2)
            window.removeEventListener('resize', this.onResize)
        },
        computed: {
            ...mapState([
                'productsData'
            ])
        },
        watch: {
            productsData: {
                handler(newVal) {
                    let curData = []
                    this.productsData.map((item, index) => {
                        curData.push(Object.assign({}, item, { isHide: false, offsetTop: 0, offsetHeight: 0 }))
                    })
                    this.curData = curData
                    if (curData.length > 0) {
                        this.$nextTick(() => {
                            this.titleHeight = this.$refs[`titleWrap0`][0].offsetHeight
                            this.curData.forEach((value, index) => {
                                value.offsetTop = this.$refs[`product${index}`][0].offsetTop
                                value.offsetHeight = this.$refs[`product${index}`][0].offsetHeight
                            });
                        })
                    }
                },
                immediate: true
            }
        },
        methods: {
            handleScroll1() {
                let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                let clientHeight = this.clientHeight
                let titleHeight = this.titleHeight
                let curData = this.curData
                let maxLength = curData.length
                let target
                let isAbsoluted = false
                let isFixed = false
                let element
                for (let index = maxLength - 1; index >= 0; index--) {
                    target = curData[index]
                    element = this.$refs[`titleWrap${index}`][0]
                    if (isAbsoluted || isFixed) {
                        element.style.position = 'absolute'
                        element.style.bottom = '0'
                        element.style.top = 'auto'
                        return
                    }
                    if (scrollTop > (target.offsetTop + target.offsetHeight - titleHeight)) {
                        isAbsoluted = true
                        element.style.position = 'absolute'
                        element.style.bottom = '0'
                        element.style.top = 'auto'
                    } else if (scrollTop > target.offsetTop) {
                        isFixed = true
                        element.style.position = 'fixed'
                        element.style.bottom = 'auto'
                        element.style.top = '0'
                    } else {
                        element.style.position = 'relative'
                        element.style.bottom = '0'
                        element.style.top = '0'
                    }
                }
            },
            handleScroll2() {
                let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                let curData = this.curData
                let maxLength = curData.length
                let isHide = false
                let clientHeight = this.clientHeight
                let target
                for (let index = maxLength - 1; index >= 0; index--) {
                    if (index === maxLength - 1) {
                        curData[index].isHide = true
                    } else {
                        if (isHide) {
                            curData[index].isHide = isHide
                        } {
                            target = curData[index + 1]

                            if (scrollTop > (target.offsetTop - clientHeight + this.titleHeight)) {
                                isHide = true
                            } else {
                                isHide = false
                            }
                            curData[index].isHide = isHide
                        }
                    }
                }
            },
            onResize() {
                setTimeout(() => {
                    if (this.curData.length > 0) {
                        this.titleHeight = this.$refs[`titleWrap0`][0].offsetHeight
                        this.curData.forEach((value, index) => {
                            value.offsetTop = this.$refs[`product${index}`][0].offsetTop
                            value.offsetHeight = this.$refs[`product${index}`][0].offsetHeight
                        });
                    }
                }, 500);
            },
            scrollById(id) {
                let scrollHeight = document.getElementById(id).offsetTop
                setTimeout(() => {
                    window.scrollTo(0, scrollHeight)
                });
            },
            handleCollapse(isHide, id) {
                let toId
                if (isHide) {
                    toId = `product-${id}`
                } else {
                    toId = `product-${id + 1}`
                }
                this.scrollById(toId)
            },
        },
        components: {
            mNavBar,
            mScrollBar,
            mFoot,
            mToTop
        }
    }
</script>

<style lang="scss" scoped>
    .m_products_page {
        color: #EEEDE8;
        background-color: #2B2B2B;

        .banner {
            position: relative;
            width: 100%;
            height: 1220px;
            background-size: contain;

            .gif {
                width: 576px;
                height: 877px;
            }

            .tag {
                bottom: 18px;
                width: 42px;
                height: 24px;
            }
        }

        .container {
            padding: 60px 0;

            .item {
                position: relative;

                .title_wrap {
                    width: 100%;
                    height: 160px;
                    color: #00FF00;

                    .wrapper {
                        width: 100%;
                        height: 160px;
                        left: 0;
                        background-color: #2B2B2B;
                        padding: 0 60px 0 90px;
                    }

                    h1 {
                        line-height: 46px;
                        font-size: 38px;
                    }

                    h2 {
                        line-height: 37px;
                        font-size: 30px;
                        margin-left: 12px;
                    }

                    .open_btn {
                        position: relative;
                        width: 42px;
                        height: 42px;

                        img {
                            transition: transform 0.3s;

                            &.actived {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .content {
                    max-height: 1500px;
                    transition: all 0.3s ease-in-out;
                    overflow: hidden;
                    padding: 0 30px;

                    &.hide {
                        max-height: 0;
                    }

                    .img_wrap {
                        width: 464px;

                        .img_1 {
                            width: 408px;
                            height: 512px;
                        }

                        .img_2 {
                            width: 30px;
                        }
                    }

                    .inspiration_wrap {
                        width: 464px;
                        padding: 60px 0 80px;

                        h3 {
                            line-height: 37px;
                            font-size: 30px;
                            color: #fff;
                        }

                        p {
                            line-height: 38px;
                            font-size: 24px;
                            margin-top: 24px;
                        }
                    }

                    .img_3_wrap {
                        .img_3 {
                            width: 38px;
                            height: 38px;
                        }

                        .second_title {
                            font-size: 20px;
                        }
                    }

                    .img_4 {
                        width: 100%;
                        height: 228px;
                        margin: 24px 0 60px;
                    }
                }
            }
        }
    }
</style>